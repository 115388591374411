import { registerApplication as registerApplicationSPA, start } from 'single-spa'
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout'
import indexLayout from './layouts/index.layout.html'
import { recordApplication } from './record.application'

export function registerApplication() {
  const routes = constructRoutes(indexLayout)
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name)
    },
  })

  const layoutEngine = constructLayoutEngine({ routes, applications })
  applications.forEach(registerApplicationSPA)
  layoutEngine.activate()
  recordApplication.forEach(config => registerApplicationSPA(config))
}

export function startApplication() {
  start()
}
