import { navigateToUrl } from 'single-spa'
import {
  authManager,
  hideLoading,
  showLoading,
  subscriptionInformer,
  SubscriptionModel,
} from '@ubits/library-core'
import {
  appcuesPage,
  getCompanyPage,
  getHomepage,
  hideFooter,
  hideZendesk,
  isAdminSetup,
  isCustomCourseCreator,
} from '../services'
import { routerList } from './router.list'
import { RouterModel } from './router.model'

export function routerService() {
  reloadHandlerAdmin()
  onRouterHashchange()
  sessionValidation()

  window.addEventListener('hashchange', () => onRouterHashchange())
}

function getRouterItem(url: string): RouterModel {
  const origin = window.location.origin
  url = url.replace(origin, '')
  if (!url || url === '/') return null

  const routerKey: string = Object.keys(routerList).find((key: string) => {
    const routerItemUrl = routerList[key].url
    let isValid: boolean = url.includes(routerItemUrl)

    if (url.length > routerItemUrl.length && isValid)
      isValid = ['?', '/'].includes(url.replace(routerItemUrl, '')[0])

    return isValid
  })

  return routerKey ? routerList[routerKey] : null
}

function updateDocumentTitle() {
  const routerItem: RouterModel = getRouterItem(window.location.href)
  if (!routerItem) return

  const routerTitle = routerItem?.title || ''
  document.title = `Ubits ${routerTitle && '|'} ${routerTitle}`.trim()
}

function onRouterHashchange() {
  handleToggleLoading()

  reloadHandlerInitial()
  reloadHandlerLMS()
  reloadHandlerLogout()
  redirectCustomCourseCreator()

  hideFooter()
  hideZendesk()
  appcuesPage()
  updateDocumentTitle()

  handleGoogleAnalitics()
}

async function redirectToHomepage() {
  redirectCustomCourseCreator()

  const isAdmin: boolean = await isAdminSetup()
  const url = isAdmin
    ? await getHomepage()
    : [await getCompanyPage(), await getHomepage()].find(url => getRouterItem(url))
  navigateToUrl(url)
}

async function redirectCustomCourseCreator() {
  if (await isCustomCourseCreator()) {
    const url = process.env['LMS_URL']
    window.location.href = `${url}/local/admincourses/index.php`
  }
}

function reloadHandlerInitial() {
  const hash = window.location.hash
  const href = window.location.href
  if (!Boolean(hash) || hash === '#/' || !getRouterItem(href)) redirectToHomepage().then()
}

async function reloadHandlerAdmin() {
  if (await isAdminSetup()) navigateToUrl(await getHomepage())
}

function reloadHandlerLMS() {
  const whitelist = ['#/lms-login']
  const hash = location.hash

  if (whitelist.some(item => hash.includes(item))) {
    redirectToHomepage().then()
    window.top.dispatchEvent(new CustomEvent('refreshLms', { bubbles: true }))
  }
}

async function reloadHandlerLogout() {
  const whitelist = ['#/logout']
  const hash = location.hash

  if (whitelist.includes(hash)) {
    showLoading(false)
    await authManager.logout()
  }
}

function handleToggleLoading() {
  showLoading(false)
  const blacklist = ['#/lms']
  const hash = location.hash
  if (!blacklist.some(item => hash.includes(item))) hideLoading()
}

function handleGoogleAnalitics() {
  const hash = location.hash.replace('#', '')

  window['dataLayer'].push({
    event: 'pageview',
    eventModel: {
      'page_location': location.origin,
      'page_referrer': 'handleGoogleAnalitics',
      'page_title': document.title,
      'hash': hash,
    },
  })
}

function sessionValidation() {
  subscriptionInformer.get().then((subscription: SubscriptionModel) => {
    const { subscriptionIsActive, userId } = subscription
    if (!subscriptionIsActive && Boolean(userId)) navigateToUrl('#/verifyValidity')
  })
}
