const blacklist: string[] = ['#/logout', 'lms']

export function hideZendesk() {
  const hash: string = location.hash
  const hide: boolean = !hash.trim().length || blacklist.some(item => hash.includes(item))

  const zendeskElement: HTMLElement =
    document.querySelector("[title='Botón para iniciar la ventana de mensajería']") ||
    document.querySelector("[title='Button to launch messaging window']")

  if (zendeskElement) zendeskElement.style.display = hide ? 'none' : 'block'
}
