import { authInformer, authManager } from '@ubits/library-core'
import { registerApplication, startApplication } from './register.application'
import { routerService } from './router'
import { footerTycEventListener } from './scripts/footer-tyc-event-listener'
import { registerAppcues, sessionManager } from './services'
import { devTools, getDevDependencies } from './utils'

async function initializeApp() {
  /* Dev Services */
  getDevDependencies()
  devTools()

  /* Auth Services */
  await authManager.initializeApp()
  const isAuthenticated: boolean = await authInformer.isAuthenticated()

  if (!isAuthenticated) return
  await sessionManager.main()

  /* Application Services */
  registerApplication()
  startApplication()

  /* Additional services and scripts */
  routerService()
  footerTycEventListener()
  await registerAppcues()
}

Promise.resolve(initializeApp())
