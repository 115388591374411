/* eslint-disable */
// @ts-nocheck

export function getDevDependencies() {
  const devDependencies = DevDependencies

  window['devDependenciesManager'] = Object.keys(devDependencies)
    .filter(key => key.includes('@ubits'))
    .reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: devDependencies[key],
      })
    }, {})

  window['allDevDependencies'] = () => {
    return Object.keys(window)
      .filter(key => key.includes('devDependencies'))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: window[key],
        })
      }, {})
  }

  window['environment'] = process.env.ENV
}
