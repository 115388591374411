const blacklist: string[] = [
  '#/admin-hr/local',
  '#/lms',
  '#/login',
  '#/logout',
  '#/playlist',
  '#/withoutfooter',
]

export function hideFooter() {
  const hash: string = location.hash
  const hide: boolean = !hash.trim().length || blacklist.some(item => hash.includes(item))

  const footerElement = document.querySelector('footer')
  if (footerElement) footerElement.style.display = hide ? 'none' : 'grid'
}
