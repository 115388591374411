import { RouterRecordModel } from './router.model'

export const routerList: RouterRecordModel = {
  adminHRCertificates: { url: '/#/admin-hr/local/certificates_request' },
  adminHRCustomization: { url: '/#/admin-hr/customization' },
  adminHRCompany: { url: '/#/admin-hr/companies' },
  adminHRHelpCenter: { url: '/#/admin-hr/help-center' },
  adminHRHome: { title: 'AdminHR', url: '/#/admin-hr/home' },
  adminHRMetrics: { title: 'AdminHR', url: '/#/admin-hr/metrics' },
  adminHRUsers: { title: 'AdminHR | Users', url: '/#/admin-hr/users' },
  adminHR: { title: 'AdminHR', url: '/#/admin-hr' },
  catalog: { title: 'Catalogo', url: '/#/catalog' },
  homeCatalog: { title: 'Catalogo', url: '/#/home/catalog' },
  homeLearning: { title: 'Learning', url: '/#/home/learning' },
  homePlaylist: { title: 'Playlist', url: '/#/home/learning/playlist' },
  homeProgram: { title: 'Programa', url: '/#/home/program' },
  homeStudyZone: { title: 'Zona de estudio', url: '/#/home/study-zone' },
  homeUbitsMax: { title: 'Ubits Max', url: '/#/home/ubits-max' },
  lmsCourse: { title: 'Curso', url: '/#/lms/course' },
  lmsStudentMetrics: { title: 'Métricas', url: '/#/lms/local/studentmetrics' },
  logout: { url: '/#/logout' },
  profile: { title: 'Perfil', url: '/#/profile' },
}
