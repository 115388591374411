import { subscriptionInformer } from '@ubits/library-core'

export async function registerAppcues() {
  const appcues = window['Appcues']
  try {
    const info = await subscriptionInformer.get()
    const userId = parseInt(info['userId'])
    if (appcues != undefined && appcues != null) {
      // set up the identity of the user
      appcues.identify(userId, {
        'created_at': info.subscriptionStartDate,
        'role': info.role,
        'first_name': info.firstName,
        'company_name': info.companyName,
        'email': info.email,
      })
    }
  } catch (error) {
    console.error('error', error)
  }
}

export function appcuesPage() {
  const appcues = window['Appcues']
  try {
    appcues?.page()
  } catch (error) {
    console.error('error', error)
  }
}
