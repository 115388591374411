import { RegisterApplicationConfig } from 'single-spa'

export const recordApplication: RegisterApplicationConfig[] = [
  {
    name: '@ubits-mf/lxp-notifications',
    app: () => System.import('@ubits-mf/lxp-notifications'),
    activeWhen: [''],
  },
  {
    name: '@ubits-mf/lxp-preference',
    app: () => System.import('@ubits-mf/lxp-preference'),
    activeWhen: [''],
  },
  {
    name: '@ubits-mf/lxp-header',
    app: () => System.import('@ubits-mf/lxp-header'),
    activeWhen: location => !['#/login', '#/withoutheader'].includes(location.hash),
  },
  {
    name: '@ubits-mf/lxp-home',
    app: () => System.import('@ubits-mf/lxp-home'),
    activeWhen: ['#/home'],
  },
  {
    name: '@ubits-mf/lxp-catalog',
    app: () => System.import('@ubits-mf/lxp-catalog'),
    activeWhen: ['#/catalog'],
  },
  {
    name: '@ubits-mf/lxp-profile',
    app: () => System.import('@ubits-mf/lxp-profile'),
    activeWhen: ['#/profile'],
  },
  {
    name: '@ubits-mf/lms',
    app: () => System.import('@ubits-mf/lms'),
    activeWhen: ['#/lms'],
  },
  {
    name: '@ubits-mf/lms-admin-hr',
    app: () => System.import('@ubits-mf/lms'),
    activeWhen: ['#/admin-hr/local'],
  },
  {
    name: '@ubits-mf/lxp-admin-hr',
    app: () => System.import('@ubits-mf/lxp-admin-hr'),
    activeWhen: ['#/admin-hr'],
  },
  {
    name: '@ubits-mf/lxp-playlist',
    app: () => System.import('@ubits-mf/lxp-playlist'),
    activeWhen: [''],
  },
]
